@import '@we-make-websites/styles-lib/src/config/configuration';
/**
 * Styles: Nail Art Card
 * -----------------------------------------------------------------------------
 * Base styles for the Nail Art Card component.
 *
 */
.nailArtCard {
  $parent: &;

  &__imageWrapper {
    background-color: var(--color-background-light);
    overflow: hidden;
    position: relative;
    padding-bottom: 116%;
  }

  &__wishlistWrapper {
    position: absolute;
    top: calc(var(--spacing-xs) + var(--spacing-3xs));
    right: calc(var(--spacing-xs) + var(--spacing-3xs));
  }

  &__cardWrapper {
    background-color: var(--color-background-light);
    border-radius: calc(var(--spacing-xs) + var(--spacing-3xs));
    overflow: hidden;
    height: 100%;
  }

  &__image {
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__proOnlyContainer {
    position: absolute;
    top: var(--font-size-l);
    left: var(--font-size-l);
    z-index: var(--layer-flat);
  }

  &__footerContainer {
    display: block;
    min-height: 78px;
    margin-block-start: var(--spacing-m);
    margin-inline-start: var(--spacing-xs);
    margin-inline-end: var(--spacing-xs);
    margin-block-end: var(--spacing-m);
    text-decoration: none;
  }

  &__title {
    margin-block-end: var(--spacing-2xs);
  }

  &__author {
    margin-block-end: var(--spacing-xs);
  }

  &__description {
    white-space: break-spaces;
  }

  &__proOnlyStyles {
    color: var(--color-white);
    background: var(--color-background-dark);
    padding: var(--spacing-3xs) var(--spacing-xs);
    border-radius: 70px;
    display: grid;
    place-items: center;
  }

  /**
   * Loading state.
   */
  &:global(.is-loading) {
    #{$parent}__imageWrapper,
    #{$parent}__title,
    #{$parent}__author,
    #{$parent}__description {
      @include loading();
    }

    #{$parent}__author {
      width: 70%;
    }

    #{$parent}__description {
      min-height: 48px;
    }
  }

  @include mq($until: l) {
    &__footerContainer {
      #{$parent}__author {
        font-size: var(--font-size-caption-m);
      }
    }
  }

  @include mq($from: l) {
    &__imageWrapper {
      padding-bottom: 111%;
    }

    &__wishlistWrapper {
      top: calc(var(--spacing-m) + var(--spacing-2xs));
      right: calc(var(--spacing-m) + var(--spacing-2xs));
    }

    &__title {
      margin-block-end: var(--spacing-2xs);
    }

    &__footerContainer {
      margin-block-start: var(--spacing-s);
      margin-block-end: var(--spacing-m);
      margin-inline-start: var(--spacing-m);
      margin-inline-end: var(--spacing-m);
      min-height: 80px;
    }

    &__author {
      margin-block-end: var(--spacing-m);
    }
  }
}
