@import '@we-make-websites/styles-lib/src/config/configuration';
.nailArtCollection {
  --constrained-offset: var(--margin-mobile);
  --slider-spacing: var(--spacing-xs);
  --itemview-transform: translateX(var(--constrained-offset));

  background-color: var(--color-background-grey);
  padding: var(--spacing-xl) 0;
  padding-inline-end: 0;
  width: 100vw;

  @include full-bleed;

  &__title {
    display: block;
  }

  &__subtitle {
    display: block;
  }

  &__headerMenuWrapper {
    column-gap: var(--spacing-s);
    display: flex;
    justify-content: flex-start;
    margin-block-start: var(--spacing-l);
    margin-inline-start: calc(-1 * var(--margin-mobile));
    overflow-x: auto;
    -ms-overflow-style: none;
    padding-inline-end: var(--margin-mobile);
    padding-inline-start: var(--margin-mobile);
    scrollbar-width: none;
    width: calc(100% + 2 * var(--margin-mobile));

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__headerMenuHidden {
    display: none;
  }

  &__headerButtonText {
    white-space: nowrap;
  }

  &__carouselWrapper {
    margin-top: var(--spacing-l);

    [data-slider-slide]:last-child [data-slider-itemview] {
      padding-right: var(--constrained-offset);
    }

    [data-slider-itemview] {
      height: 100%;
    }
  }

  &__cardWrapper {
    width: 228px;
  }

  &__hideCarousel {
    display: none;
  }

  &__carousel {
    --arrow-top-spacing: 132px;
  }

  @include mq($from: l) {
    $viewport-width: calc(100vw - var(--scrollbar-width, 0));
    --constrained-offset: calc(
      max(var(--max-content-width), #{$viewport-width}) / 2 -
        (var(--max-content-width) - var(--margin-desktop) * 2) / 2
    );
    padding: var(--spacing-5xl) 0;
    padding-inline-end: 0;

    &__headerWrapper {
      display: flex;
      justify-content: space-between;
    }

    &__headerMenuWrapper {
      column-gap: var(--spacing-m);
      align-items: flex-end;
      margin: 0;
      overflow: unset;
      padding: 0;
      width: unset;
    }

    &__titleWrapper {
      margin: 0;
    }

    &__carouselWrapper {
      [data-previous-button] {
        left: var(--constrained-offset);
      }

      [data-next-button] {
        right: var(--constrained-offset);
      }
    }

    &__cardWrapper {
      width: 300px;
    }

    &__carousel {
      --arrow-top-spacing: 166px;
    }
  }
}
